import { Br, PageHeader } from "components";
import React from "react";

const ContactUsPageHeader = () => {
  return (
    <PageHeader
      title={
        <>
          Bring your financial <Br on="desktop" />
          Idea to life
        </>
      }
      subheading={
        <>
          We are your partners to execute whatever idea you have{" "}
          <Br on="desktop" />
          around financial services and digital into meaningful products.
        </>
      }
      center
      button={{ text: "Build with Parkway", to: "/get-demo" }}
      theme="light"
    />
  );
};

export { ContactUsPageHeader };
