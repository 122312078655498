import { Container, PLink, Text } from "components";
import React from "react";
import ctl from "@netlify/classnames-template-literals";
import PhoneIcon from "svgs/phone.svg";
import EmailIcon from "svgs/email.svg";
import LocationIcon from "svgs/location.svg";

const GetInTouch = () => {
  return (
    <Container>
      <div className={getInTouchWrapperStyle}>
        <Text value="Get in touch" variant="h3" className="mb-3" />
        <Text
          value="Feel free to contact us for support or any inquiries."
          variant="p18"
        />

        <div className={contactsListStyle}>
          {contactList.map((contact, index) => {
            return (
              <div key={`contact_list_item_${index}`}>
                <PLink {...contact.link}>
                  {contact.icon}
                  <Text
                    variant="h4"
                    value={contact.text}
                    className={contactText}
                  />
                </PLink>
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
};

const getInTouchWrapperStyle = ctl(`
bg-secondary
text-secondary-100
md:py-[90px]
py-[150px]
md:px-[112px]
px-[48px]
md:mt-[154px]
mt-[125px]
mb-[98px]
md:text-left
text-center
bg-[url('../assets/images/brand-pattern-contact.png')]
bg-cover
bg-center
`);

const contactsListStyle = ctl(`
flex
lg:flex-row
flex-col
gap-[64px]
md:mt-[52px]
mt-[44px]
text-center
`);

const contactIconStyle = ctl(`
mx-auto
mb-4
h-[48px]
`);

const contactText = ctl(`
xl:w-68
`);

const contactList = [
  // {
  //   icon: <PhoneIcon className={contactIconStyle} />,
  //   text: "+234 1 4543219",
  //   link: { href: { url: "tel:+23414543219" } },
  // },
  // {
  //   icon: <EmailIcon className={contactIconStyle} />,
  //   text: "info@parkway.ng",
  //   link: { href: { url: "mailto:info@parkway.ng" } },
  // },
  {
    icon: <PhoneIcon className={contactIconStyle} />,
    text: "07000-PARKWAY (070007275929)",
    link: { href: { url: "tel:070007275929" } },
  },
  {
    icon: <EmailIcon className={contactIconStyle} />,
    text: "reachout@parkway.ng",
    link: { href: { url: "mailto:reachout@parkway.ng" } },
  },
  {
    icon: <LocationIcon className={contactIconStyle} />,
    text: "295 Herbert Macaulay way, Yaba, Lagos State.",
    link: {
      href: {
        url: "https://www.google.com/maps/place/295+Herbert+Macaulay+Way,+Alagomeji-Yaba+101245,+Lagos/@6.5016625,3.3772154,17z/data=!3m1!4b1!4m6!3m5!1s0x103b8c605d666603:0x6f86f6acf4a2a947!8m2!3d6.5016625!4d3.3772154!16s%2Fg%2F11g8b9jkfy?entry=ttu",
      },
    },
  },
];

export { GetInTouch };
