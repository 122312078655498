import * as React from "react";

import { Layout } from "components";

import { ContactUsPageHeader, GetInTouch } from "templates/contact-us";

const ContactUsPage = () => (
  <Layout title="Contact us" theme="light">
    <ContactUsPageHeader />
    <GetInTouch />
  </Layout>
);

export default ContactUsPage;
